import React from 'react';
import styled from 'styled-components';
import { COLORS, MEDIA_QUERY } from '../../commonStyles';
import { MultilineParagraph } from '../../components/ui/text';
import { Button } from '../../components/ui/button/button';
import { Image } from '../../types/basicTypes';

type Props = {
  image: Image;
  title: string;
  subtitle: string;
  description: string;
  ctaButton: {
    url: string;
    text: string;
  };
};

const Row = styled.div({
  width: '100%',
  display: 'flex',
});

const CtaContainer = styled.div({
  position: 'relative',
  zIndex: 2,
  padding: '0 15px',
  textAlign: 'center',
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    maxWidth: '480px',
    margin: 'auto',
  },
  [MEDIA_QUERY.DESKTOP]: {
    maxWidth: '688px',
  },
});

const Title = styled.h1({
  width: '100%',
  maxWidth: '100%',
  textAlign: 'center',
  fontSize: '44px',
  marginBottom: '0px',
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    fontSize: '56px',
  },
});

const Description = styled.div({
  width: '100%',
  [MEDIA_QUERY.DESKTOP]: {
    maxWidth: '480px',
    margin: 'auto',
  },
});

const CtaButton = styled.div({
  margin: '32px auto 48px auto',
  display: 'flex',
  [MEDIA_QUERY.DESKTOP]: {
    margin: '32px auto 32px auto',
  },
});

const Container = styled.div({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  color: COLORS.BLACK,
});

const HeroImageContainer = styled.div({
  height: '60vh',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  [MEDIA_QUERY.DESKTOP]: {
    height: '70vh',
  },
  [MEDIA_QUERY.SMALL_DESKTOP]: {
    height: '75vh',
  },
});

const HeroImage = styled.img({
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  [MEDIA_QUERY.DESKTOP]: {
    maxWidth: '996px',
  },
  [MEDIA_QUERY.BIG_TABLET]: {
    maxWidth: '100%',
  },
  [MEDIA_QUERY.MEDIUM_DESKTOP]: {
    maxWidth: '1280px',
  },
  [MEDIA_QUERY.BIG_DESKTOP]: {
    maxWidth: '1920px',
  },
  [MEDIA_QUERY.HUGE_DESKTOP]: {
    maxWidth: '2560px',
  },
});

export const HeroSection: React.FC<Props> = ({
  image,
  title,
  description,
  ctaButton,
}) => {
  return (
    <Container>
      <HeroImageContainer>
        <HeroImage src={image.file.url} />
      </HeroImageContainer>
      <CtaContainer>
        <Row>
          <Title>{title}</Title>
        </Row>
        <Row>
          <Description>
            <MultilineParagraph>{description}</MultilineParagraph>
          </Description>
        </Row>
        <Row>
          <CtaButton>
            <Button.Primary fill as="a" href={ctaButton.url}>
              {ctaButton.text}
            </Button.Primary>
          </CtaButton>
        </Row>
      </CtaContainer>
    </Container>
  );
};
