import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import Testimonials from '../../components/testimonials';
import { Layout } from '../../components';
import { Category, Description, Image, Props } from '../../types/basicTypes';
import { HeroSection } from './hero';
import { CategorySlide, CategorySlider } from './category-slider';
import { MEDIA_QUERY } from '../../commonStyles';

const landingPageData = (language: string) => {
  const { allContentfulLandingPage } = useStaticQuery(graphql`
    query LandingPageQuery {
      allContentfulLandingPage {
        nodes {
          node_locale
          getToKnowTheService
          titleOfDescription
          description {
            description
          }
          testimonials {
            title
            mainImage {
              file {
                url
              }
            }
            mainImageTablet {
              file {
                url
              }
            }
            mainImageMobile {
              file {
                url
              }
            }
            firstTestimonial
            firstTestimonialAuthor
            firstTestimonialImage {
              file {
                url
              }
            }
            secondTestimonial
            secondTestimonialAuthor
            secondTestimonialImage {
              file {
                url
              }
            }
            thirdTestimonial
            thirdTestimonialAuthor
            thirdTestimonialImage {
              file {
                url
              }
            }
          }
          slidingCategories {
            name
            id
            contentful_id
            image {
              file {
                url
              }
            }
          }
          ctaButtonUrl
          ctaButtonText
          heroTitle
          heroSubtitle
          heroDescription {
            heroDescription
          }
          heroImage {
            file {
              url
            }
          }
        }
      }
    }
  `);
  return allContentfulLandingPage.nodes.find(
    ({ node_locale: nodeLocale }: { node_locale: string }) =>
      nodeLocale === language
  );
};

type Testimonials = {
  title: string;
  mainImage: Image;
  mainImageTablet: Image;
  mainImageMobile: Image;
  firstTestimonial: string;
  firstTestimonialAuthor: string;
  firstTestimonialImage: Image;
  secondTestimonial: string;
  secondTestimonialAuthor: string;
  secondTestimonialImage: Image;
  thirdTestimonial: string;
  thirdTestimonialAuthor: string;
  thirdTestimonialImage: Image;
};

type Data = {
  heroImage: Image;
  heroTitle: string;
  heroSubtitle: string;
  heroDescription: {
    heroDescription: string;
  };
  ctaButtonText: string;
  ctaButtonUrl: string;
  getToKnowTheService: string;
  titleOfDescription: string;
  description: Description;
  landingPageFirstImageDesktop: Image;
  landingPageFirstImageTablet: Image;
  landingPageFirstImageMobile: Image;
  testimonials: Testimonials;
  slidingCategories: Category[];
};

const LandingPage: React.FC<Props> = ({ pageContext: { language } }) => {
  const {
    testimonials,
    slidingCategories,
    heroTitle,
    heroSubtitle,
    heroDescription,
    ctaButtonText,
    ctaButtonUrl,
    heroImage,
  }: Data = landingPageData(language);
  return (
    <>
      <Layout
        language={language}
        title={heroTitle}
        disableMaxWidth
        fittingPadding
      >
        <HeroSection
          image={heroImage}
          title={heroTitle}
          subtitle={heroSubtitle}
          description={heroDescription.heroDescription}
          ctaButton={{ url: ctaButtonUrl, text: ctaButtonText }}
        />
        <Container>
          <CategorySlider categories={slidingCategories} language={language} />
          <Testimonials data={testimonials} />
        </Container>
      </Layout>
    </>
  );
};

const Container = styled.div`
  margin: 0 auto;
  padding: 50px 15px 15px 15px;
  width: 100%;
  box-sizing: border-box;

  ${MEDIA_QUERY.MOBILE_AND_TABLET} {
    padding-top: 0px;
  }
`;

export default LandingPage;
