import React from 'react';
import { Category, Image } from '../../types/basicTypes';
import styled from 'styled-components';
import {
  COLORS,
  LANDING_PAGE_MEDIA_QUERY,
  MEDIA_QUERY,
} from '../../commonStyles';
import { getLink } from '../../constants/links';
import {
  normalizeTitle,
  useCategoryFirstProductLink,
} from '../../functions/utils';

export type CategorySlide = {
  name: string;
  image: Image;
};

const CategorySlideContainer = styled.a({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  color: COLORS.BLACK,
  textDecoration: 'none',
  cursor: 'pointer',
  marginBottom: '32px',
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    width: '30%',
  },
});

const CategorySlideName = styled.div({
  width: '100%',
  backgroundColor: COLORS.MERCURY_GREY,
  padding: '16px',
  fontSize: '20px',
  lineHeight: '32px',
  fontWeight: 'bold',
  textAlign: 'center',
});

const CategorySlideImage = styled.img({
  flexShrink: 0,
  height: '100%',
  width: '100%',
  objectFit: 'cover',
});

const CategorySlideImageContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  height: '300px',
  width: '100%',
});

const CategorySlide: React.FC<{ category: Category; language: string }> = ({
  category,
  language,
}) => {
  const { name, image } = category;
  const href = useCategoryFirstProductLink(language, category, true);

  return (
    <CategorySlideContainer href={href}>
      <CategorySlideImageContainer>
        <CategorySlideImage alt={name} src={image.file.url} />
      </CategorySlideImageContainer>
      <CategorySlideName>{name}</CategorySlideName>
    </CategorySlideContainer>
  );
};

const Container = styled.div({
  width: '100%',
  maxWidth: '990px',
  margin: 'auto',
  [LANDING_PAGE_MEDIA_QUERY.TABLET]: {
    maxWidth: '688px',
  },
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

type CategorySliderProps = {
  categories: Category[];
  language: string;
};

export const CategorySlider: React.FC<CategorySliderProps> = ({
  categories,
  language,
}) => {
  return (
    <Container>
      {categories.map((category, index) => (
        <CategorySlide
          language={language}
          key={`${category.name}-${index}`}
          category={category}
        />
      ))}
    </Container>
  );
};
