import React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY } from '../../../commonStyles';

const P = styled.p({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  margin: '24px 0 0 0',
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    fontSize: '18px',
    lineHeight: '28px',
  },
});

type Props = {
  children: string;
};

export const MultilineParagraph: React.FC<Props> = ({ children }) => {
  return (
    <>
      {children.split('\n').map((line, index) => (
        <P key={`${line.slice(0, 10)}-${index}`}>{line} </P>
      ))}
    </>
  );
};
