import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';

import {
  H3,
  MEDIA_QUERY,
  LANDING_PAGE_MEDIA_QUERY,
  MEDIA_QUERY_MIN_RESOLUTIONS,
} from '../commonStyles';

interface Props {
  data: {
    title: string;
    mainImage: {
      file: {
        url: string;
      };
    };
    mainImageTablet: {
      file: {
        url: string;
      };
    };
    mainImageMobile: {
      file: {
        url: string;
      };
    };
    firstTestimonial: string;
    firstTestimonialAuthor: string;
    firstTestimonialImage: {
      file: {
        url: string;
      };
    };
    secondTestimonial: string;
    secondTestimonialAuthor: string;
    secondTestimonialImage: {
      file: {
        url: string;
      };
    };
    thirdTestimonial: string;
    thirdTestimonialAuthor: string;
    thirdTestimonialImage: {
      file: {
        url: string;
      };
    };
  };
}

const Testimonials: React.FC<Props> = props => {
  const mainImage = props.data.mainImage.file.url;
  const mainImageTablet = props.data.mainImageTablet.file.url;
  const mainImageMobile = props.data.mainImageMobile.file.url;
  const firstImage = props.data.firstTestimonialImage.file.url;
  const secondImage = props.data.secondTestimonialImage.file.url;
  const thirdImage = props.data.thirdTestimonialImage.file.url;
  return (
    <ImageAndDescriptionContainer>
      <RectangleWithDescription>
        <Title>{props.data.title}</Title>
        <Testimonial>
          <TestimonialImageContainer>
            <MediaQuery maxWidth={MEDIA_QUERY_MIN_RESOLUTIONS.TABLET - 1}>
              <TestimonialImage
                src={`${firstImage}?fm=jpg&fl=progressive&fit=fill&w=224`}
                alt={props.data.firstTestimonialAuthor}
              />
            </MediaQuery>
            <MediaQuery
              minWidth={MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}
              maxWidth={MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP}
            >
              <TestimonialImage
                src={`${firstImage}?fm=jpg&fl=progressive&fit=fill&w=180`}
                alt={props.data.firstTestimonialAuthor}
              />
            </MediaQuery>
            <MediaQuery minWidth={MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP + 1}>
              <TestimonialImage
                src={`${firstImage}?fm=jpg&fl=progressive&fit=fill&w=200`}
                alt={props.data.firstTestimonialAuthor}
              />
            </MediaQuery>
          </TestimonialImageContainer>
          <TestimonialText>
            <TestimonialDesc>{props.data.firstTestimonial}</TestimonialDesc>
            <TestimonialAuthor>
              &ndash; {props.data.firstTestimonialAuthor}
            </TestimonialAuthor>
          </TestimonialText>
        </Testimonial>

        <Testimonial>
          <TestimonialImageContainer>
            <MediaQuery maxWidth={MEDIA_QUERY_MIN_RESOLUTIONS.TABLET - 1}>
              <TestimonialImage
                src={`${secondImage}?fm=jpg&fl=progressive&fit=fill&w=224`}
                alt={props.data.secondTestimonialAuthor}
              />
            </MediaQuery>
            <MediaQuery
              minWidth={MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}
              maxWidth={MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP}
            >
              <TestimonialImage
                src={`${secondImage}?fm=jpg&fl=progressive&fit=fill&w=180`}
                alt={props.data.secondTestimonialAuthor}
              />
            </MediaQuery>
            <MediaQuery minWidth={MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP + 1}>
              <TestimonialImage
                src={`${secondImage}?fm=jpg&fl=progressive&fit=fill&w=180`}
                alt={props.data.secondTestimonialAuthor}
              />
            </MediaQuery>
          </TestimonialImageContainer>
          <TestimonialText>
            <TestimonialDesc>{props.data.secondTestimonial}</TestimonialDesc>
            <TestimonialAuthor>
              &ndash; {props.data.secondTestimonialAuthor}
            </TestimonialAuthor>
          </TestimonialText>
        </Testimonial>

        <Testimonial>
          <TestimonialImageContainer>
            <MediaQuery maxWidth={MEDIA_QUERY_MIN_RESOLUTIONS.TABLET - 1}>
              <TestimonialImage
                src={`${thirdImage}?fm=jpg&fl=progressive&fit=fill&w=224`}
                alt={props.data.thirdTestimonialAuthor}
              />
            </MediaQuery>
            <MediaQuery
              minWidth={MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}
              maxWidth={MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP}
            >
              <TestimonialImage
                src={`${thirdImage}?fm=jpg&fl=progressive&fit=fill&w=180`}
                alt={props.data.thirdTestimonialAuthor}
              />
            </MediaQuery>
            <MediaQuery minWidth={MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP + 1}>
              <TestimonialImage
                src={`${thirdImage}?fm=jpg&fl=progressive&fit=fill&w=200`}
                alt={props.data.thirdTestimonialAuthor}
              />
            </MediaQuery>
          </TestimonialImageContainer>
          <TestimonialText>
            <TestimonialDesc>{props.data.thirdTestimonial}</TestimonialDesc>
            <TestimonialAuthor>
              &ndash; {props.data.thirdTestimonialAuthor}
            </TestimonialAuthor>
          </TestimonialText>
        </Testimonial>
      </RectangleWithDescription>
      <MainImageContainer>
        <MediaQuery maxWidth={345}>
          <MainImage
            src={`${mainImageMobile}?fm=jpg&fl=progressive&fit=fill&w=345&h=345`}
            alt={props.data.title}
          />
        </MediaQuery>
        <MediaQuery
          minWidth={346}
          maxWidth={MEDIA_QUERY_MIN_RESOLUTIONS.EXTRA_TABLET}
        >
          <MainImage
            src={`${mainImageTablet}?fm=jpg&fl=progressive&fit=fill&w=688&h=456`}
            alt={props.data.title}
          />
        </MediaQuery>
        <MediaQuery minWidth={MEDIA_QUERY_MIN_RESOLUTIONS.EXTRA_TABLET + 1}>
          <MainImage
            src={`${mainImage}?fm=jpg&fl=progressive&fit=fill&w=513&h=984`}
            alt={props.data.title}
          />
        </MediaQuery>
      </MainImageContainer>
    </ImageAndDescriptionContainer>
  );
};

const ImageAndDescriptionContainer = styled.div`
  font-family: Arial;
  display: flex;
  align-items: stretch;
  width: 1247px;
  max-height: 1200px;
  margin: auto;
  margin-top: 72px;
  ${LANDING_PAGE_MEDIA_QUERY.TABLET} {
    width: 100%;
    max-width: 688px;
    max-height: unset;
    flex-direction: column-reverse;
  }
  ${MEDIA_QUERY.MOBILE_AND_TABLET} {
    margin-top: 0px;
  }
`;

const Title = styled(H3)`
  padding: 60px 36px 36px 36px;
  width: unset;
  flex: 0;
  ${LANDING_PAGE_MEDIA_QUERY.TABLET} {
    padding-bottom: 24px;
    flex: 1 100%;
  }
`;

const MainImageContainer = styled.div`
  flex-basis: 40%;
  position: relative;
  display: flex;
  @media (max-width: 1277px) {
    flex-basis: 100%;
  }
`;

const MainImage = styled.img`
  object-fit: cover;
`;

const RectangleWithDescription = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  ${LANDING_PAGE_MEDIA_QUERY.TABLET} {
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;
  }
  ${MEDIA_QUERY.MOBILE} {
    flex-direction: column;
    flex-wrap: unset;
    flex-basis: 100%;
  }
`;

const Testimonial = styled.div`
  padding: 18px 36px;
  flex: 1;
  display: flex;
  align-items: center;
  :last-child {
    padding-bottom: 36px;
  }
  ${LANDING_PAGE_MEDIA_QUERY.TABLET} {
    padding: 30px 25px;
    flex-direction: column;
    align-items: stretch;
  }
  ${MEDIA_QUERY.MOBILE} {
    padding: 40px 60px;
    margin: 0 auto;
    max-width: 350px;
  }
`;

const TestimonialImageContainer = styled.div`
  flex: 1;
  display: block;
`;

const TestimonialImage = styled.img`
  border-radius: 100%;
  width: 100%;
  height: 100%;
`;

const TestimonialText = styled.div`
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  padding-left: 32px;
  flex: 2;
  display: block;
  flex-direction: column;
  justify-content: center;
  ${LANDING_PAGE_MEDIA_QUERY.TABLET} {
    padding-left: 0;
    padding-top: 12px;
    font-size: 14px;
    line-height: 1.43;
  }
  ${MEDIA_QUERY.MOBILE} {
    padding-top: 15px;
    text-align: center;
  }
`;

const TestimonialDesc = styled.div``;

const TestimonialAuthor = styled.div`
  margin-top: 10px;
  font-size: 18px;
  font-style: italic;
  ${LANDING_PAGE_MEDIA_QUERY.TABLET} {
    font-size: 14px;
  }
`;

export default Testimonials;
