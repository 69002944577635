import styled from 'styled-components';
import { COLORS, MEDIA_QUERY } from '../../../commonStyles';

type Props = {
  fill?: boolean;
};

const BaseButton = styled.button<Props>(
  {
    padding: '16px 32px',
    outline: 'none',
    backgroundColor: 'transparent',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '2px',
    cursor: 'pointer',
    textAlign: 'center',
    borderWidth: '2px',
    borderStyle: 'solid',
    [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
      letterSpacing: 'normal',
      fontSize: '24px',
    },
    [MEDIA_QUERY.DESKTOP]: {
      padding: '16px 64px',
    },
  },
  ({ fill }) => {
    const styles = {
      width: fill ? '100%' : 'initial',
    };

    return styles;
  }
);

export const Button = {
  Primary: styled(BaseButton)({
    borderColor: COLORS.BLACK,
    color: COLORS.BLACK,
  }),
};
